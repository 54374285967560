import { createUseStyles } from "react-jss"

export const usePaperStyles = createUseStyles((theme) => ({
  root: {
    backgroundColor: theme.color.background.transparentEmphasis,

    width: "100%",
    height: "100%",
    display: "inline-block",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow:
      "0px 2px 2px 0px rgba(30, 31, 42, 0.24), 0px 4px 4px 0px rgba(30, 31, 42, 0.24), 0px 8px 8px 0px rgba(30, 31, 42, 0.24)",
    // border: `1px solid ${theme.color.border.divider}`,
    // borderRadius: `calc(${theme.shape.borderRadius.xs} * 12)`,
    borderRadius: "4px",
    // height: 324
  },
}))
