import { GET_LOCATION, TENANT_ADMIN_BASE_URL } from "src/constants"
import { RoleValueType } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import useSWR from "swr"

type UseLocationType = {
  shouldFetch: boolean
}

export type LocationType = {
  country?: string
  countryCode?: string
  city?: string
  lat?: string
  lon?: string
  ipAddress?: string
}

type UseLocationResponseType = {
  data?: LocationType
  isLoading: boolean
  error: any
  isValidating: boolean
  getLocationInfo: () => void
}

const parseLocationData = (data: any): LocationType => ({
  ...data,
  ipAddress: data?.query,
})

const LOCATION_REFRESH_INTERVAL = 1000 * 60 * 60 * 2 // 2 hours

const LOCATION_API_KEY = process.env.REACT_APP_LOCATION_API_KEY

const useLocation = ({ shouldFetch }: UseLocationType, role: RoleValueType): UseLocationResponseType => {
  const url =
    isTenantRole(role)
      ? `${TENANT_ADMIN_BASE_URL}${GET_LOCATION}?key=${LOCATION_API_KEY}`
      : `${GET_LOCATION}?key=${LOCATION_API_KEY}`

  const { data, error, mutate, isValidating } = useSWR(shouldFetch ? url : null, {
    refreshInterval: LOCATION_REFRESH_INTERVAL,
  })

  return {
    data: data ? parseLocationData(data.payload) : undefined,
    isLoading: !error && !data,
    error,
    getLocationInfo: mutate,
    isValidating,
  }
}

export default useLocation
