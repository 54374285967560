import clsx from "clsx"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { APPLICATION_TROUBLESHOOTING_ERROR_DETAIL, APPLICATION_TROUBLESHOOTING_LOGS } from "src/constants"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import theme from "src/theme"
import { Status, StatusType } from "../ApplicationTroubleshooting"
import StepCircle, { Props as StepCircleProps } from "./StepCircle"
import { useTroubleshootingStepStyles } from "./TroubleshootingStep.styles"

export interface Props extends StepCircleProps {
  evaluationId: string
  status: null | StatusType
  heading: string
  subHeading: string
  errorText: string | null
  hideVerticalLine: boolean
}

const TroubleshootingStep = ({
  evaluationId,
  circleNumber,
  circleType,
  status,
  heading,
  subHeading,
  errorText,
  hideVerticalLine,
}: Props) => {
  const classes = useTroubleshootingStepStyles()

  const showLogDownloadIcon = heading === "Log Collection" && status === Status.SUCCESS

  const isPacketCapture = heading === "Packet Capture"

  const mapQueryParams: Record<string, string> = {
    "Service Connector Cloud Connectivity": "host",
    "Application Connectivity Status": "host",
    "Tunnel Status": "relay,device",
    "Agent Status": "device",
  }

  const handleDownloadErrorDetails = (values: { evaluationId: string; queryParams: string }) =>
    window.open(APPLICATION_TROUBLESHOOTING_ERROR_DETAIL(values), "_blank")

  const handleDownloadLog = (evaluationId: string) =>
    window.open(APPLICATION_TROUBLESHOOTING_LOGS(evaluationId), "_blank")

  const failedUiStatues = [Status.FAILED, Status.TERMINATED, Status.TIMEOUT]

  return (
    <div className={classes.root}>
      <div>
        <StepCircle circleNumber={circleNumber} circleType={circleType} />

        {!hideVerticalLine && (
          <div className={classes.verticalLine}>
            <div />
          </div>
        )}
      </div>

      <div className={classes.stepContent}>
        {!!status && (
          <div
            className={clsx(
              classes.statusCircle,
              { [classes.success]: status === Status.SUCCESS },
              { [classes.failed]: failedUiStatues.includes(status as Status) },
              { [classes.inProgress]: status === Status.IN_PROGRESS },
            )}
          >
            <ZtnaIcon
              name={
                status === Status.SUCCESS
                  ? "enDone"
                  : failedUiStatues.includes(status as Status)
                  ? "enClose"
                  : "enDotsHorizontal"
              }
              style={{ color: theme.color.content.inverseDefault }}
            />
          </div>
        )}

        <div>
          <div className={classes.headingContainer}>
            <ENTextPassage>
              <p className={clsx(classes.heading, { [classes.currentHeading]: circleType === "current" })}>{heading}</p>
            </ENTextPassage>

            {isPacketCapture && (
              <ENTextPassage>
                <p className={clsx(classes.heading, classes.currentHeading, classes.cursorDisabled)}>
                  Start Packet Capture
                </p>
              </ENTextPassage>
            )}
          </div>

          <div className={classes.textWithIcon}>
            <ENTextPassage>
              <p className={classes.subHeading}>{status === Status.IN_PROGRESS ? "In Progress.." : subHeading}</p>
            </ENTextPassage>

            {showLogDownloadIcon && (
              <div role="button" className={classes.cursorPointer} onClick={() => handleDownloadLog(evaluationId)}>
                <ZtnaIcon name="enDownload" size="md" style={{ color: theme.color.content.accentDefault }} />
              </div>
            )}
          </div>

          {errorText && (
            <div className={classes.textWithIcon}>
              <ENTextPassage>
                <p className={classes.subHeading}>
                  <strong>Result/Output:</strong> {errorText}
                </p>
              </ENTextPassage>

              {status === Status.FAILED && (
                <ZtnaTooltip title="Download Error Details" placement="bottom">
                  <div
                    role="button"
                    className={classes.cursorPointer}
                    onClick={() => handleDownloadErrorDetails({ evaluationId, queryParams: mapQueryParams[heading] })}
                  >
                    <ZtnaIcon name="enDownload" size="md" style={{ color: theme.color.content.accentDefault }} />
                  </div>
                </ZtnaTooltip>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TroubleshootingStep
