import { createUseStyles } from "react-jss"

export const useDialogActionsStyles = createUseStyles(() => ({
  dialogActionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8,
    minHeight: 75,
  },
}))
