import clsx from "clsx"
import { ENTextarea } from "en-react/dist/src/components/Textarea"
import { ENTextField } from "en-react/dist/src/components/TextField"
import React, { Fragment, useCallback, useState } from "react"
import { Controller } from "react-hook-form"
import ZtnaIcon from "src/shared/components/Icons"
import { useFormComponentsStyles } from "../FormComponents.styles"
import { EnInputType, EnSimpleInputType } from "../FormComponents.types"

const SimpleInput = (props: EnSimpleInputType): JSX.Element => {
  const { name, control, isOptional, isDisabled, ...rest } = props

  return (
    <Controller
      control={control}
      name={name}
      render={(props) => (
        <EnInput
          {...rest}
          onChange={(e: any) => {
            props.field.onChange(e?.target?.value)
          }}
          onBlur={() => props.field.onBlur()}
          value={props.field.value}
          isActive={props.field.value !== ""}
          isOptional={isOptional}
          disable={isDisabled}
        />
      )}
    />
  )
}

const EnInput = (props: EnInputType) => {
  const {
    inputId,
    dataTestId,
    value,
    onChange,
    onBlur,
    isActive,
    appendStyles = "",
    label = "",
    placeholder = "",
    isPasswordField,
    error,
    tableError,
    multiline = false,
    hideLabel = false,
    rows = 1,
    disable = false,
    autoComplete = "off",
    isReadOnly,
    preIconName,
    postIconName,
    type,
    units,
    autoFocus,
    onKeydown,
    fieldNote,
    isFullWidth = false,
    isOptional,
    disableResize,
    onKeyUp,
  } = props
  const classes = useFormComponentsStyles()

  const [isVisible, setVisible] = useState(false)
  const handleClickShowPassword = useCallback((): void => {
    setVisible(!isVisible)
  }, [isVisible])

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
  }
  return (
    <>
      {multiline ? (
        <ENTextarea
          fieldId={inputId}
          data-testid={dataTestId}
          isDisabled={disable}
          label={label}
          value={value}
          styleModifier={appendStyles}
          placeholder={placeholder}
          isOptional={isOptional}
          handleOnChange={(e: any) => {
            onChange(e)
          }}
          isActive={isActive !== undefined ? isActive : value !== ""}
          isError={error || tableError}
          errorNote={error ? error.message : tableError}
          isReadonly={isReadOnly}
          isFocused={autoFocus}
          rows={rows}
          fieldNote={fieldNote}
          onBlur={onBlur}
          isDisabledResize={disableResize}
          onKeyUp={onKeyUp}
        />
      ) : (
        <ENTextField
          enableSlotAfterClick
          fieldId={inputId}
          data-testid={dataTestId}
          id={inputId}
          isDisabled={disable}
          autoComplete={autoComplete}
          label={label}
          isOptional={isOptional}
          autoCorrect="off"
          autoCapitalize="none"
          value={value}
          styleModifier={appendStyles}
          type={type ? type : isVisible || !isPasswordField ? "text" : "password"}
          placeholder={placeholder}
          handleOnChange={(e: any) => {
            onChange(e)
          }}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          isActive={isActive !== undefined ? isActive : value !== ""}
          isError={error || tableError}
          errorNote={error ? error.message : tableError}
          isReadonly={isReadOnly}
          isFocused={autoFocus}
          onKeyDown={onKeydown}
          fieldNote={fieldNote}
          className={clsx({ [classes.fullWidth]: isFullWidth })}
          hideLabel={hideLabel}
        >
          {preIconName && (
            <span slot="before" className={classes.prePostIcon}>
              <ZtnaIcon name={preIconName} />
            </span>
          )}
          {postIconName && (
            <span slot="after" className={classes.prePostIcon}>
              <ZtnaIcon name={postIconName} />
            </span>
          )}
          {units && (
            <Fragment>
              <span slot="after">{units}</span>
            </Fragment>
          )}
          {isPasswordField && (
            <Fragment>
              <span
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                className={classes.passwordIcon}
                onMouseDown={handleMouseDownPassword}
                slot="after"
              >
                {isVisible ? <ZtnaIcon name="eyeOutlineSlash" /> : <ZtnaIcon name="eyeOutline" />}
              </span>
            </Fragment>
          )}
        </ENTextField>
      )}
    </>
  )
}

export default SimpleInput
