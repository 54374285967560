import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { toast, ToastContainer, Id } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { openModal } from "src/store/ui"
import { ModalNamesType } from "src/store/ui/types"
import theme from "src/theme"
import { useUpgradeBannerStyles, useUpgradeButtonStyles, useUpgradeMessageStyles } from "./UpgradeBanner.styles"
import { useConnectorsNotifications } from "./useConnectorsNotifications"
import { useRelayNodeNotifications } from "./useRelayNodeNotifications"
import { Roles } from "src/routes/config"
import { RADSEC_PROXY_SHOULD_INITIATE } from "src/craas/apiURLs"
import useFetch from "src/hooks/useFetch"

const mapStatus = {
  UPGRADE_PENDING: "Upgrade Pending",
  UPGRADE_IN_PROGRESS: "Upgrade In-Progress",
  UPGRADE_FAILED: "Upgrade Failed",
} as const

export type NotificationsParsedType = {
  status: (typeof mapStatus)[keyof typeof mapStatus]
  message: string
  latestVersion?: string
  version?: string
}

interface UpgradeMessageProps {
  showInfoIcon: boolean
  message: string
  subText?: string
}

const UpgradeMessage = ({ showInfoIcon, message, subText }: UpgradeMessageProps) => {
  const classes = useUpgradeMessageStyles()

  return (
    <ENTextPassage>
      <div className={classes.root}>
        {showInfoIcon && <ZtnaIcon name="enInfo" size="lg" style={{ color: theme.color.neutralLight[16] }} />}
        <div>
          <p className={classes.text} dangerouslySetInnerHTML={{ __html: message }} />
          {subText && <p className={classes.subtext} dangerouslySetInnerHTML={{ __html: subText }} />}
        </div>
      </div>
    </ENTextPassage>
  )
}

interface UpgradeButtonProps {
  title: string
  modalName: ModalNamesType
  callback?: () => void
  disabled?: boolean
  showTooltip?: boolean
}

const UpgradeButton = ({ title, modalName, callback, disabled, showTooltip }: UpgradeButtonProps) => {
  const classes = useUpgradeButtonStyles()
  const dispatch = useDispatch()

  return showTooltip ? (
    <ZtnaTooltip
      title="Please Look for the disclaimer icon next to proxy software version. Click to resolve the errors."
      arrow
      placement="left"
      customStyle={{ minHeight: 40, minWidth: 190, padding: 18 }}
      cssPosition="absolute"
      enableTextWrap={false}
    >
      <ZtnaButton
        variant="text"
        title={title}
        buttonType="primary"
        className={classes.button}
        onClick={() => {
          if (!disabled) {
            callback?.()
            dispatch(openModal(modalName))
          }
        }}
        disabled={disabled}
      />
    </ZtnaTooltip>
  ) : (
    <ZtnaButton
      variant="text"
      title={title}
      buttonType="primary"
      className={classes.button}
      onClick={() => {
        if (!disabled) {
          callback?.()
          dispatch(openModal(modalName))
        }
      }}
      disabled={disabled}
    />
  )
}

interface UpgradeBannerProps extends NotificationsParsedType {
  type: "connector" | "relayNode" | "radsecProxy"
  position?: number
  role?: any
}

const UpgradeBanner = ({ type, status, message, position, role }: UpgradeBannerProps) => {
  const classes = useUpgradeBannerStyles({ position })

  const { data: initiateProxy, isLoading, isValidating } = useFetch({
    apiUrl: (type === "radsecProxy" && role === Roles.CUSTOMER_SUPER_ADMIN) ? RADSEC_PROXY_SHOULD_INITIATE : "",
  })

  const { getRelayNodeNotifications } = useRelayNodeNotifications({ shouldFetch: type === "relayNode" })
  const { getConnectorsNotifications } = useConnectorsNotifications({ shouldFetch: type === "connector" })

  let buttonTitle = ""
  let callback = () => {}
  let modalName: ModalNamesType | null = null

  if (status === "Upgrade Pending") {
    buttonTitle = "Initiate Upgrade"
    modalName =
      type === "connector"
        ? "upgradeAllConnectors"
        : type === "radsecProxy"
        ? "upgradeRadsecProxyVersion"
        : "upgradeAllRelayNodes"
  } else if (status === "Upgrade Failed") {
    buttonTitle = "Retry Upgrade"
    modalName = type === "connector" ? "retryConnectorUpgrade" : "retryRelayUpgrade"
  } else if (type === "radsecProxy" && status !== "Upgrade In-Progress") {
    buttonTitle = "Initiate Upgrade"
  } else {
    buttonTitle = "View Progress"
    callback = type === "connector" ? getConnectorsNotifications : getRelayNodeNotifications
    modalName = type === "connector" ? "upgradeConnectorDetails" : "upgradeRelayNodeDetails"
  }
  const toastId = useRef<Id>(`toast-${type}`)
  useEffect(() => {
    if (!isLoading && !isValidating) {
      const isUpgradePending = status === "Upgrade Pending"
      const isProxyUpgradeInProgress = status === "Upgrade In-Progress" && type === "radsecProxy"

      let disabled = false
      let subText = "Please wait for the on-going upgrade to complete."
      if (isProxyUpgradeInProgress) {
        disabled = true
      }
      const shouldDisableButton = !initiateProxy?.shouldInitiate && type === "radsecProxy"


      if (toastId.current && !toast.isActive(toastId.current)) {
        toastId.current = toast(
          <UpgradeMessage
            message={message}
            showInfoIcon={isUpgradePending}
            subText={disabled && !isProxyUpgradeInProgress ? subText : ""}
          />,
          {
            toastId: toastId.current,
            closeButton: (
              <UpgradeButton
                title={buttonTitle}
                modalName={modalName as ModalNamesType}
                callback={callback}
                disabled={disabled || shouldDisableButton}
                showTooltip={ shouldDisableButton}
              />
            ),
          }
        )
      } else if (toastId.current) {
        toast.update(toastId.current, {
          render: (
            <UpgradeMessage message={message} showInfoIcon={isUpgradePending} subText={disabled ? subText : ""} />
          ),
          closeButton: (
            <UpgradeButton
              title={buttonTitle}
              modalName={modalName as ModalNamesType}
              callback={callback}
              disabled={disabled || shouldDisableButton}
              showTooltip={shouldDisableButton}
            />
          ),
        })
      }
    }
  }, [message, initiateProxy, isLoading, isValidating, status, type, callback, modalName])

  return (
    <ToastContainer
      position="top-center"
      closeOnClick={false}
      draggable={false}
      className={classes.root}
      hideProgressBar
      bodyStyle={{ padding: 0, margin: 0 }}
      autoClose={false}
      limit={1}
      containerId={type}
    />
  )
}

export default UpgradeBanner
