import { Paper } from "@material-ui/core"
import { ICellRendererParams } from "ag-grid-community"
import clsx from "clsx"
import { ENButton } from "en-react/dist/src/components/Button"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { ENList } from "en-react/dist/src/components/List"
import { ENListItem } from "en-react/dist/src/components/ListItem"
import { ENMenu } from "en-react/dist/src/components/Menu"
import React, { useEffect, useRef, useState } from "react"
import { PopoverButtonType } from "src/components/DataGrid/DataGrid"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { useDataGridPopOverStyles } from "./DataGridPopOver.styles"

const DataGridPopover = <RowDataType extends any>(
  props: Omit<ICellRendererParams<RowDataType>, "setTooltip"> & {
    popOverList: PopoverButtonType[]
    isExpandedView?: boolean
  },
) => {
  const popoverRef = useRef<HTMLInputElement>(null)
  const [popoverMenuPosition, setPopoverMenuPosition] = useState({ top: 500, left: 900 })

  const classes = useDataGridPopOverStyles({ top: popoverMenuPosition.top, left: popoverMenuPosition.left })

  const hasNoItems = props.popOverList?.every(
    (item) =>
      (item.hide && !!item.hide(props.data)) || (item?.show && !item?.show(props.data)) || item.variant === "separator",
  )
  const calculatePopoverMenuPosition = () => {
    const rect = popoverRef?.current?.getBoundingClientRect()
    const top = rect?.top ? rect.top + window.scrollY : 0
    const left = rect?.left ? rect.left - 180 : 0
    setPopoverMenuPosition({ top, left })
  }
  useEffect(() => {
    if (props.isExpandedView) {
      calculatePopoverMenuPosition()
      if (popoverRef.current) {
        popoverRef.current.addEventListener("click", calculatePopoverMenuPosition)
      }
      window.addEventListener("resize", calculatePopoverMenuPosition)
      return () => {
        if (popoverRef.current) {
          popoverRef.current.removeEventListener("click", calculatePopoverMenuPosition)
        }
        window.removeEventListener("resize", calculatePopoverMenuPosition)
      }
    }
  }, [])

  const PopOverChildComponent = (
    <React.Fragment key=".0">
      {props.popOverList?.map(
        ({
          name,
          title,
          callback,
          disabled,
          variant = "normal",
          show,
          tooltipText,
          hide,
          disabledTooltipText,
          disabledTooltipTextPlacement,
        }) => {
          const disabledTooltipTitle = disabled?.(props.data)
            ? typeof disabledTooltipText === "function"
              ? disabledTooltipText(props.data)
              : disabledTooltipText
            : tooltipText || ""

          if (variant === "separator") {
            return <ENDivider />
          }

          return (
            <>
              {disabledTooltipTitle !== "" ? (
                <ZtnaTooltip
                  title={disabledTooltipTitle}
                  arrow
                  key={`PopoverTooltip-${name}`}
                  placement={disabledTooltipTextPlacement || "bottom"}
                  enableTextWrap
                >
                  <ENListItem
                    key={name}
                    isDisabled={(disabled && disabled(props.data)) || false}
                    onClick={() => {
                      callback?.(name, props.data)
                    }}
                    style={(hide && !!hide(props.data)) || (show && !show(props.data)) ? { display: "none" } : {}}
                  >
                    <div className={classes.popOverMenuItem}>
                      <span>{typeof title === "function" ? title(props.data) : title}</span>
                    </div>
                  </ENListItem>
                </ZtnaTooltip>
              ) : (
                <ENListItem
                  key={name}
                  isDisabled={(disabled && disabled(props.data)) || false}
                  onClick={() => {
                    callback?.(name, props.data)
                  }}
                  style={(hide && !!hide(props.data)) || (show && !show(props.data)) ? { display: "none" } : {}}
                >
                  <div className={classes.popOverMenuItem}>
                    <span>{typeof title === "function" ? title(props.data) : title}</span>
                  </div>
                </ENListItem>
              )}
            </>
          )
        },
      )}
    </React.Fragment>
  )
  const menuItemsToShow = props?.popOverList?.filter((item: any) => item?.show?.(props.data))

  return (
    <ENMenu
      position={
        props?.node?.rowIndex &&
        props?.node?.rowIndex - props?.api.paginationGetCurrentPage() * props?.api.paginationGetPageSize() >= 6
          ? "top-left"
          : "left"
      }
      height={menuItemsToShow?.length >= 5 ? 250 : undefined} // 250 is the max height of the popover if the list is more than 5 items. This to add scroll to the popover
    >
      <React.Fragment key=".0">
        <div className={classes.actionButton} slot="trigger" ref={popoverRef}>
          {hasNoItems ? (
            <ZtnaTooltip
              title={hasNoItems ? "No action can be taken with the current state of the resource" : ""}
              placement="left"
            >
              <ENButton variant="tertiary">
                <React.Fragment key=".0">
                  <ZtnaIcon name="actionsDataGrid" />
                </React.Fragment>
              </ENButton>
            </ZtnaTooltip>
          ) : (
            <ENButton variant="tertiary">
              <React.Fragment key=".0">
                <ZtnaIcon name="actionsDataGrid" />
              </React.Fragment>
            </ENButton>
          )}
        </div>

        {!hasNoItems ? (
          <div
            className={clsx({
              [classes.listContainerExpanded]: props.isExpandedView,
              [classes.listContainer]: !props.isExpandedView,
            })}
          >
            <ENList>
              {props.isExpandedView ? (
                <Paper className={classes.paperContainer}>{PopOverChildComponent}</Paper>
              ) : (
                <>{PopOverChildComponent}</>
              )}
            </ENList>
          </div>
        ) : null}
      </React.Fragment>
    </ENMenu>
  )
}

export default DataGridPopover
