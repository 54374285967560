import { GridApi, IRowNode } from "ag-grid-community"
import { ENIconRefresh } from "en-react/dist/src/components/Icons/Refresh"
import { ENTextField } from "en-react/dist/src/components/TextField"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useState } from "react"
import { useAppAbility } from "src/casl/ability"
import { PopoverButtonType } from "src/components/DataGrid/DataGrid"
import EnSelect from "src/shared/components/FormComponents/EnSelect"
import ZtnaIcon from "src/shared/components/Icons"
import ZtnaButton from "src/shared/components/ZtnaButton"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import theme from "src/theme"
import DataGridPopover from "../DataGridPopOver"
import { useDataGridStyles } from "./DataGridNavigationBar.style"

type DataGridNavigationBarProps = {
  onSearchCallBack?: (searchText: string) => void
  debouncedSearchHandler?: (value: string) => void
  showRowsSelected?: boolean
  numberOfRowsSelected?: number
  label: string
  onActionCallBack?: () => void
  actionText: string
  serverSideRefreshing: boolean
  onDragUpCallBack?: () => void
  dragUpText: string
  onSaveCallBack?: () => void
  saveText: string
  onDragDownCallBack?: () => void
  dragDownText: string
  onRefreshCallBack?: () => void
  onBulkActionPopover?: {
    popOverList: PopoverButtonType[]
  }
  refreshIntervalSeconds?: number
  groupByCallBack?: {
    options: { [key: string]: string }[]
    onChange?: (selectedGroups: { [key: string]: string }) => void
    configValue?: string
  }
}

const DataGridNavigationBar: React.FC<DataGridNavigationBarProps> = ({
  onSearchCallBack,
  debouncedSearchHandler,
  numberOfRowsSelected,
  label,
  onActionCallBack,
  actionText,
  showRowsSelected,
  onDragUpCallBack,
  dragUpText,
  onSaveCallBack,
  saveText,
  onDragDownCallBack,
  dragDownText,
  onRefreshCallBack,
  onBulkActionPopover,
  refreshIntervalSeconds,
  groupByCallBack,
}) => {
  const classes = useDataGridStyles()
  const showGridActionButtons = useAppAbility().can("edit", "gridOrdering")
  const [searchValue, setSearchValue] = useState("")
  const showActionBar = onActionCallBack && numberOfRowsSelected !== 0
  const showBulkActionMenu = onBulkActionPopover && !!numberOfRowsSelected
  const showDragUpButton = showGridActionButtons && onDragUpCallBack && !!numberOfRowsSelected
  const showDragDownButton = showGridActionButtons && onDragDownCallBack && !!numberOfRowsSelected
  const showSaveButton = showGridActionButtons && onSaveCallBack && !!numberOfRowsSelected
  const popOverParams = {
    value: "",
    valueFormatted: "",
    data: "",
    node: {} as IRowNode<string>,
    headerName: "",
    maxWidth: 50,
    rowIndex: 0,
    eGridCell: {} as HTMLElement,
    eParentOfValue: {} as HTMLElement,
    api: {} as GridApi<string>,
    columnApi: {} as any,
    context: "",
    registerRowDragger: () => {},
  }

  const handleKeyDown = (e: any) => {
    if (e?.key !== "Enter") {
      setSearchValue(e?.key)
    }
    if (e.key === "Enter") {
      debouncedSearchHandler?.(searchValue)
    }
  }

  const handleOnChange = (e: any) => {
    const value = e.target.value
    setSearchValue(value)
    if (value === "") {
      debouncedSearchHandler?.(value)
    }
  }

  return (
    <>
      <div className={classes.tableTaskBar}>
        {onSearchCallBack && (
          <div slot="actions-left" className={classes.searchBar}>
            <ENTextField
              hideLabel
              name="Search"
              placeholder="Search"
              onKeyDown={handleKeyDown}
              handleOnChange={handleOnChange}
            />
          </div>
        )}
        <div className={classes.rightAligner}>
          {showActionBar && (
            <div className={classes.actionBar}>
              {numberOfRowsSelected !== 0 && showRowsSelected && (
                <div slot="actions-right">
                  <ENTextPassage>
                    [{numberOfRowsSelected}] {label} Selected
                  </ENTextPassage>
                </div>
              )}
              {onActionCallBack && numberOfRowsSelected !== 0 && (
                <div slot="actions-right">
                  <ZtnaButton
                    buttonType="tertiary"
                    title={actionText}
                    contentColor={theme.color.content.accentDefault}
                    onClick={onActionCallBack}
                  />
                </div>
              )}
            </div>
          )}

          {showDragDownButton && (
            <div className={classes.actionBar}>
              {onDragDownCallBack && (
                <div slot="actions-right">
                  <ZtnaButton
                    buttonType="tertiary"
                    title={dragDownText}
                    contentColor={theme.color.content.accentDefault}
                    onClick={onDragDownCallBack}
                    endIcon={<ZtnaIcon name="enChevronDown" color={theme.color.content.accentDefault} />}
                  />
                </div>
              )}
            </div>
          )}

          {showDragUpButton && (
            <div className={classes.actionBar}>
              {onDragUpCallBack && (
                <div slot="actions-right">
                  <ZtnaButton
                    buttonType="tertiary"
                    title={dragUpText}
                    contentColor={theme.color.content.accentDefault}
                    onClick={onDragUpCallBack}
                    endIcon={<ZtnaIcon name="enChevronUp" color={theme.color.content.accentDefault} />}
                  />
                </div>
              )}
            </div>
          )}

          {showSaveButton && (
            <div className={classes.actionBar}>
              {onSaveCallBack && (
                <div slot="actions-right">
                  <ZtnaButton
                    buttonType="secondary"
                    title={saveText}
                    contentColor={theme.color.content.accentDefault}
                    onClick={onSaveCallBack}
                  />
                </div>
              )}
            </div>
          )}

          {groupByCallBack?.options && (
            <div className={classes.marginRight}>
              <EnSelect
                options={groupByCallBack.options}
                value={groupByCallBack?.configValue}
                onChange={(selectedGroups) => groupByCallBack?.onChange?.(selectedGroups) || (() => {})}
              />
            </div>
          )}

          {onRefreshCallBack && (
            <>
              {refreshIntervalSeconds ? (
                <ZtnaTooltip
                  title={refreshIntervalSeconds ? `Data refreshes every ${refreshIntervalSeconds} seconds` : undefined}
                  placement="left"
                >
                  <div role="presentation" onClick={onRefreshCallBack} style={{ marginRight: 16 }}>
                    <ENIconRefresh slot="end" size="md" />
                  </div>
                </ZtnaTooltip>
              ) : (
                <div role="presentation" onClick={onRefreshCallBack} style={{ marginRight: 16 }}>
                  <ENIconRefresh slot="end" size="md" />
                </div>
              )}
            </>
          )}

          {showBulkActionMenu && (
            <div slot="actions-right">
              <DataGridPopover {...popOverParams} popOverList={onBulkActionPopover.popOverList} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DataGridNavigationBar
