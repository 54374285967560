import { Dayjs } from "dayjs"
import { ENTimePicker } from "en-react/dist/src/components/TimePicker"
import React, { useEffect, useState } from "react"
import { useZtnaEnTimePickerStyles } from "./ZtnaEnTimePicker.styles"

type TimeState = { hours: number | undefined; minutes: number | undefined; seconds: number | undefined }

interface Props {
  label?: string
  placeholder?: string
  onChange: (dateTime: TimeState | null) => void
  value?: Dayjs | null
  disabled?: boolean
  error?: any
  optional?: boolean
}

const ZtnaEnTimePicker: React.FC<Props> = ({
  label = "Label",
  placeholder,
  onChange,
  value,
  error,
  disabled,
  optional,
}) => {
  const classes = useZtnaEnTimePickerStyles()
  const [time, setTime] = useState<string>("")

  useEffect(() => {
    if (value) {
      const valueString = `${value.hour().toString().padStart(2, "0") || "00"}:${
        value.minute().toString().padStart(2, "0") || "00"
      }:${value.second().toString().padStart(2, "0") || "00"}`

      setTime(valueString)
    }
  }, [value])

  const handleTimeChange = (e: any) => {
    const newValue = e.detail?.value
    const splitObject = newValue.split(":")
    const timeObject = {
      hours: splitObject[0] || "00",
      minutes: splitObject[1] || "00",
      seconds: splitObject[2] || "00",
    }
    setTime(newValue)
    onChange(newValue ? timeObject : null)
  }

  return (
    <div className={classes.container}>
      <ENTimePicker
        label={label}
        placeholder={placeholder}
        value={time}
        onTimeChange={handleTimeChange}
        variant="primary"
        isDisabled={disabled}
        errorNote={error}
        isError={!!error}
        className={classes.fullWidthInput}
        isOptional={optional}
      />
    </div>
  )
}

export default ZtnaEnTimePicker
