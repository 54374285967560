import React, { PropsWithChildren } from "react"
import { usePaperStyles } from "./Paper.styles"

type Props = {}

const Paper = (props: PropsWithChildren<Props>) => {
  const { children } = props
  const styles = usePaperStyles()

  return <div className={styles.root}>{children}</div>
}

export default Paper
