import { Tooltip } from "@material-ui/core"
import { ColDef } from "ag-grid-community"
import clsx from "clsx"
import { ENChip } from "en-react/dist/src/components/Chip"
import ZtnaIcon from "src/shared/components/Icons"
import ZtnaButton from "src/shared/components/ZtnaButton"
import theme from "src/theme"
import { isInArray } from "src/utils"
import { CellRendererProps } from "../../DataGrid.types"
import { useBadgeWithIconCellStyles } from "./BadgeWithIconCell.styles"
interface RowDataType {
  [key: string]: any
}

type BadgeConfigType = {
  secondaryValues: string[]
  tertiaryValues: string[]
  successValues: string[]
  warningValues: string[]
  dangerValues: string[]
  infoValues: string[]
  primaryValues: string[]
  badgeTooltipKey?: string
  fallbackData?: string
}

const getChipVariant = (value: string, colDef: ColDef) => {
  const {
    secondaryValues = [],
    tertiaryValues = [],
    successValues = [],
    warningValues = [],
    dangerValues = [],
    infoValues = [],
    primaryValues = [],
  } = colDef?.cellRendererParams?.badgeConfig || ({} as BadgeConfigType)

  if (typeof value === "string") {
    if (isInArray(value, secondaryValues)) return "secondary"
    if (isInArray(value, tertiaryValues)) return "tertiary"
    if (isInArray(value, successValues)) return "green"
    if (isInArray(value, warningValues)) return "amber"
    if (isInArray(value, dangerValues)) return "red"
    if (isInArray(value, infoValues)) return "blue"
    if (isInArray(value, primaryValues)) return "purple"
  }
  return "secondary"
}

const BadgeWithIcons: React.FC<CellRendererProps> = ({ data, colDef }) => {
  const rowData = data as RowDataType
  const cellData = colDef.field && rowData[colDef.field]
  const classes = useBadgeWithIconCellStyles()
  const { cellRendererParams } = colDef
  const { badgeConfig, alignChild } = cellRendererParams
  const {
    badgeTooltipKey = "",
    iconTooltipKey = "",
    icon = "",
    iconColor = "",
    iconConfiguration,
    tooltipPlacement = "bottom",
    badgePaddingLeft = 0,
  } = badgeConfig

  return (
    <div className={classes.badgeWrapper} style={{ justifyContent: alignChild, paddingLeft: badgePaddingLeft }}>
      {badgeTooltipKey && rowData[badgeTooltipKey] ? (
        <>
          <Tooltip
            title={<div style={{ whiteSpace: "pre-line" }}>{rowData[badgeTooltipKey as string]}</div>}
            placement={tooltipPlacement}
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <div>
              <ENChip variant={getChipVariant(cellData, colDef)}>{cellData}</ENChip>
            </div>
          </Tooltip>

          {isInArray(cellData, iconConfiguration?.showIconValues(rowData) || []) &&
            iconConfiguration?.iconTooltipKey && (
              <Tooltip title={rowData[iconConfiguration?.iconTooltipKey || ""] || ""} arrow>
                <ZtnaButton
                  buttonType="tertiary"
                  className={clsx(classes.clickableBadgeIcon, {
                    [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                  })}
                  onClick={() => iconConfiguration?.onIconClick?.(rowData)}
                  startIcon={
                    <ZtnaIcon
                      name={iconConfiguration?.iconName || "encircledInfo"}
                      color={theme.color.neutralDark[16]}
                    />
                  }
                />
              </Tooltip>
            )}
          {isInArray(cellData, iconConfiguration?.showIconValues(rowData) || []) &&
            !iconConfiguration?.iconTooltipKey && (
              <ZtnaButton
                buttonType="tertiary"
                className={clsx(classes.clickableBadgeIcon, classes.clickableBadgeIconNoPadding, {
                  [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                })}
                onClick={() => iconConfiguration?.onIconClick?.(rowData)}
                startIcon={
                  <ZtnaIcon name={iconConfiguration?.iconName || "encircledInfo"} color={theme.color.neutralDark[16]} />
                }
              />
            )}
        </>
      ) : (
        <div className={classes.badgeWithoutTooltipWrapper}>
          <ENChip variant={getChipVariant(cellData, colDef)}>{cellData}</ENChip>
          {isInArray(cellData, iconConfiguration?.showIconValues(rowData) || []) &&
            iconConfiguration?.iconTooltipKey && (
              <Tooltip title={rowData[iconConfiguration?.iconTooltipKey || ""] || ""} arrow>
                <ZtnaButton
                  buttonType="tertiary"
                  className={clsx(classes.clickableBadgeIcon, {
                    [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                  })}
                  onClick={() => iconConfiguration?.onIconClick?.(rowData)}
                  startIcon={
                    <ZtnaIcon
                      name={iconConfiguration?.iconName || "encircledInfo"}
                      color={theme.color.neutralDark[16]}
                    />
                  }
                />
              </Tooltip>
            )}
          {isInArray(cellData, iconConfiguration?.showIconValues(rowData) || []) &&
            !iconConfiguration?.iconTooltipKey && (
              <ZtnaButton
                buttonType="tertiary"
                className={clsx(classes.clickableBadgeIcon, {
                  [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                })}
                onClick={() => iconConfiguration?.onIconClick?.(rowData)}
                startIcon={
                  <ZtnaIcon name={iconConfiguration?.iconName || "encircledInfo"} color={theme.color.neutralDark[16]} />
                }
              />
            )}
        </div>
      )}
      {icon && (
        <Tooltip
          title={rowData[iconTooltipKey] as string}
          placement="bottom"
          arrow
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
          <div data-testid={`${icon}-icon`} className={classes.badgeIcon}>
            {iconTooltipKey && rowData[iconTooltipKey] && (
              <ZtnaIcon name={icon} width="15" height="15" color={iconColor} />
            )}
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default BadgeWithIcons
