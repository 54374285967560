import { createUseStyles } from "react-jss"

export const useAppHeaderLayoutStyles = createUseStyles((theme) => ({
  layout: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    backgroundColor: theme.color.background.surfaceElevation0,
    padding: theme.spacing(1.5, 0),
  },
  left: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    flexGrow: 1,
  },
  right: {
    marginLeft: "auto",
  },
}))
