import { createUseStyles } from "react-jss"

const topPos = (position: number): string => {
  // to prevent banners from overlapping
  const basePosition = 1
  const offset = 5
  return `${basePosition + offset * position}em`
}

export const useUpgradeBannerStyles = createUseStyles<string, any>((theme) => ({
  root: {
    top: ({ position }) => topPos(position),
    padding: 0,
    margin: 0,
    width: 800,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    zIndex: theme.zIndex.upgradeRelayBanner,
    "& .Toastify__toast": {
      width: 800,
      [theme.breakpoints.down("md")]: {
        padding: "3px 0",
        width: "100%",
      },
      border: `1px solid ${theme.color.border.divider}`,
      borderRadius: 6,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      height: "auto",
      minHeight: "auto",
      backgroundColor: theme.color.background.surfaceElevation1,
      alignItems: "center",
    },
    "& .Toastify__toast-container": {
      width: 800,
      height: "auto",
    },
    "& .Toastify__progress-bar": {
      background: theme.color.background.accentDefault,
    },
  },
}))

export const useUpgradeMessageStyles = createUseStyles((theme) => ({
  root: {
    gap: 20,
    paddingLeft: 15,
    display: "flex",
    alignItems: "center",
  },
  text: {
    color: theme.color.neutralDark[13],
  },
  subtext: {
    color: theme.color.content.secondary,
    fontSize: 14,
  },
}))

export const useUpgradeButtonStyles = createUseStyles((theme) => ({
  button: {
    padding: 14,
    minWidth: 172,
  },
}))
